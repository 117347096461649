define("discourse/plugins/discourse-landing-pages/discourse/components/global-admin", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page-global"], function (_exports, _component, _object, _computed, _landingPageGlobal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_obj = {
    updatingGlobal: (0, _computed.or)("destroyingGlobal", "savingGlobal"),
    didReceiveAttrs() {
      this.initializeProps();
    },
    initializeProps() {
      this.setProperties({
        scripts: this.global?.scripts,
        jsonHeader: JSON.stringify(this.global?.header || undefined, null, 4),
        jsonFooter: JSON.stringify(this.global?.footer || undefined, null, 4)
      });
    },
    saveGlobal() {
      this.setProperties({
        savingGlobal: true,
        jsonHeaderError: null,
        jsonFooterError: null
      });
      let header;
      try {
        header = JSON.parse(this.jsonHeader || "null");
      } catch (e) {
        this.set("jsonHeaderError", e.message);
      }
      let footer;
      try {
        footer = JSON.parse(this.jsonFooter || "null");
      } catch (e) {
        this.set("jsonFooterError", e.message);
      }
      if (this.jsonHeaderError || this.jsonFooterError) {
        this.setProperties({
          savingGlobal: false,
          resultIcon: "times"
        });
        setTimeout(() => this.set("resultIcon", null), 10000);
        return;
      }
      const data = {
        global: {
          scripts: this.scripts,
          header,
          footer
        }
      };
      _landingPageGlobal.default.save(data).then(result => {
        if (result.success) {
          this.setProperties({
            resultIcon: "check",
            global: data.global
          });
          this.initializeProps();
        } else {
          this.set("resultIcon", "times");
        }
        setTimeout(() => this.set("resultIcon", null), 10000);
      }).finally(() => this.set("savingGlobal", false));
    },
    destroyGlobal() {
      this.set("destroyingGlobal", true);
      _landingPageGlobal.default.destroy().then(result => {
        if (result.success) {
          this.setProperties({
            resultIcon: "check",
            global: {}
          });
          this.initializeProps();
        } else {
          this.set("resultIcon", "times");
        }
        setTimeout(() => this.set("resultIcon", null), 10000);
      }).finally(() => this.set("destroyingGlobal", false));
    }
  }, (_applyDecoratedDescriptor(_obj, "saveGlobal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "saveGlobal"), _obj), _applyDecoratedDescriptor(_obj, "destroyGlobal", [_object.action], Object.getOwnPropertyDescriptor(_obj, "destroyGlobal"), _obj)), _obj));
});
define("discourse/plugins/discourse-landing-pages/discourse/initializers/landing-pages-edits", ["exports", "discourse/lib/url"], function (_exports, _url) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "landing-pages-edits",
    initialize(container) {
      const site = container.lookup("site:main");
      const existing = _url.default.routeTo;
      _url.default.routeTo = function (url, opts) {
        let parser = document.createElement("a");
        parser.href = url;
        if (parser.pathname && site.landing_paths.includes(parser.pathname.replace(/^\//, ""))) {
          return window.location = url;
        }
        return existing.apply(this, [url, opts]);
      };
    }
  };
});
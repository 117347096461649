define("discourse/plugins/discourse-landing-pages/discourse/routes/admin-plugins-landing-pages", ["exports", "discourse/plugins/discourse-landing-pages/discourse/models/landing-page", "discourse/lib/ajax", "discourse/routes/discourse", "discourse/models/group", "@ember/object", "rsvp"], function (_exports, _landingPage, _ajax, _discourse, _group, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _landingPage.default.all();
    },
    afterModel(model) {
      return (0, _rsvp.all)([this._getThemes(model), this._getGroups(model)]);
    },
    setupController(controller, model) {
      controller.setProperties({
        pages: model.pages,
        menus: model.menus,
        remote: _object.default.create(model.remote || {}),
        themes: model.themes,
        groups: model.groups,
        global: model.global
      });
      if (model.remote) {
        if (model.remote.commit) {
          controller.send("commitsBehind");
        } else {
          controller.set("pagesNotFetched", true);
        }
      }
    },
    _getThemes(model) {
      return (0, _ajax.ajax)("/admin/themes").then(result => {
        (0, _object.set)(model, "themes", result.themes.map(t => {
          return {
            id: t.id,
            name: t.name
          };
        }));
      });
    },
    _getGroups(model) {
      return _group.default.findAll().then(groups => {
        (0, _object.set)(model, "groups", groups);
      });
    }
  });
});
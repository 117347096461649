define("discourse/plugins/discourse-landing-pages/discourse/models/landing-page-global", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _object, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const basePath = "/landing/global";
  const LandingPageGlobal = _object.default.extend();
  LandingPageGlobal.reopenClass({
    save(data) {
      return (0, _ajax.ajax)(`${basePath}`, {
        type: "PUT",
        data
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy() {
      return (0, _ajax.ajax)(`${basePath}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = LandingPageGlobal;
});